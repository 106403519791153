import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import './Gallery.css';

const galleryList = [
    'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/kubelikova-gallery8.jpg',
    'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/kubelikova-gallery7.jpg',
    'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/kubelikova-gallery6.jpg',
    'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/kubelikova-gallery5.jpg',
    'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/interier1.jpg',
    'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/interier2.jpg',
    'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/interier3.jpg',
    'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/interier4.jpg',
    'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/kubelikova-gallery1.jpg',
    'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/kubelikova-gallery2.jpg',
    'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/kubelikova-gallery3.jpg',
    'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/kubelikova-gallery4.jpg',
    'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/portfolio-04.png',
    'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/portfolio-05.png'
]

function Gallery() {
    const [galleryData, setGalleryData] = useState({
        tab1: 0,
        isOpen: false,
    });
    function openLightBox(i) {
        setGalleryData({ isOpen: true, tab1: i });
    }
    function mapGallery() {
        if (galleryList.length) {
            return galleryList.map((value, i) => {
                return (
                    <>
                        <div className="col-lg-4 col-md-6 col-12 pt--30" key={i}>
                            {galleryData.isOpen && (
                                <Lightbox
                                    enableZoom={false}
                                    animationDuration={300}
                                    animationOnKeyInput={true}
                                    mainSrc={galleryList[galleryData.tab1]}
                                    nextSrc={galleryList[(galleryData.tab1 + 1) % galleryList.length]}
                                    prevSrc={galleryList[(galleryData.tab1 + galleryList.length - 1) % galleryList.length]}
                                    onCloseRequest={() => setGalleryData({ ...galleryData, isOpen: false })}

                                    onMovePrevRequest={() =>
                                        setGalleryData({
                                            ...galleryData, tab1: (galleryData.tab1 + galleryList.length - 1) % galleryList.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        setGalleryData({
                                            ...galleryData, tab1: (galleryData.tab1 + 1) % galleryList.length,
                                        })
                                    }
                                />
                            )}
                            <div className="portfolio-style--3" >
                                <div className=" thumbnail" onClick={() => openLightBox(i)}>
                                    <div className="cursor-pointer thumbnail-image" >
                                        <img className="w-100" src={value} alt="Portfolio Images" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                )
            }
            )
        }

    }
    return (
        <>
            <div className="row row--5" style={{display: "flex", justifyContent: "center"}}>
                {mapGallery()}
            </div>
        </>
    )
}

export default Gallery;