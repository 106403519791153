// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import MainDemo from './home/MainDemo';
import LandingPage from './home/LandingPage';
import Paralax from './home/Paralax';

// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';

// Element Layout
import error404 from "./elements/error404";
// redux imports
import { Provider,  } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux'; // applyMiddleware for reduxThunk, compose for redux devtools
import reduxThunk from 'redux-thunk'; // Used for asynchronous action creators
import reducers from './reducers';

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import LandingSplitScreen from './page-demo/LandingSplitScreen';
import ObchodniPodminky from './elements/ObchodniPodminky';
import OsobniUdaje from './elements/OsobniUdaje';
import NewsDetail from "./elements/NewsDetail";
import Contact from './elements/Contact';
import PageDataWrapper from './PageDataWrapper';
//import PDFPage from './elements/PDFPage';
// setup devtools for debugging redux
const composeEnhancers = /*window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||*/ compose;
// create redux store from combined reducers
const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(reduxThunk))
    );
    


class Root extends Component{
    render(){
        return(
             
                <Provider store={store}>
                    <BrowserRouter basename={'/'}>
                        <PageDataWrapper>
                            <PageScrollTop>
                                <Switch>
                                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={LandingPage}/>
                                    <Route exact path={`${process.env.PUBLIC_URL}/kontakt`} component={Contact}/>
                                    <Route exact path={`${process.env.PUBLIC_URL}/obchodni-podminky`} component={ObchodniPodminky}/>
                                    <Route exact path={`${process.env.PUBLIC_URL}/novinky/:title`} component={NewsDetail}/>
                                    <Route exact path={`${process.env.PUBLIC_URL}/osobni-udaje`} component={OsobniUdaje}/>
                                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                                    
                                    <Route component={error404}/>

                                </Switch>
                            </PageScrollTop>
                        </PageDataWrapper>
                    </BrowserRouter>
                </Provider>
         
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();