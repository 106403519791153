import React, { useState, Fragment } from "react";
import { Link } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import { useSelector } from 'react-redux';
import ScrollToTop from '../component/common/ScrollTop';
import { FiX, FiMenu } from "react-icons/fi";
import NewsList from "../elements/NewsList";
import CounterOne from "../elements/counters/CounterTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import '../component/header/Header.css';
import { BsHouse } from "react-icons/bs";
import { GiHomeGarage } from "react-icons/gi";
import { GoLocation, GoLinkExternal } from "react-icons/go";
import './ProjectPage.css';
import 'react-image-lightbox/style.css';
import Gallery from "../component/gallery/Gallery";
import LocationMap from "../component/common/LocationMap";
import ModalVideo from 'react-modal-video';

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Rodinný trojdům',
        description: 'KUBELÍKOVA - LIBEREC',
        buttonText: 'Kontaktujte Nás',
        buttonLink: 'kontakt'
    }
]

const ServiceListOne = [
    {
        icon: <BsHouse size={60} color="#0184f0" />,
        title: 'Prostorný domov',
        description: 'Každá jednotka má podlahovou plochu 96 m² a je o dispozici 4 + 1.'
    },
    {
        icon: <GiHomeGarage size={60} color="#0184f0" />,
        title: 'Parkovací místo',
        description: 'Parkovací místo ke každé jednotce.'
    },
    {
        icon: <GoLocation size={60} color="#0184f0" />,
        title: 'Skvělá lokalita',
        description: 'Oblast s kompletní občanskou vybaveností. '
    },

]

function LandingPage() {
    const appUI = useSelector(state => state.appUI);
    const [logoColorStyle, setLogoColorStyle] = useState("header-logo-white")
    const [videoOpen, setVideoOpen] = useState(false);
    const [galleryData, setGalleryData] = useState({
        tab1: 0,
        isOpen: false,
    });
    function menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    function CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    window.addEventListener('scroll', function () {
        var value = window.scrollY;


        if (document.querySelector('.header--fixed')) {
            if (value > 50) {
                document.querySelector('.header--fixed').classList.add('sticky')
                setLogoColorStyle("header-logo-dark");
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
                setLogoColorStyle("header-logo-white");
            }
        }



    });
    function renderNews() {

    }
    var getUrl = window.location;
    var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[0];
    var orderStyle = null;
    if (window.innerWidth >= 1200) {
        orderStyle = 'order-2';
    }



    function stickyHeader() { }


    var elements = document.querySelectorAll('.has-droupdown > a');
    for (var i in elements) {
        if (elements.hasOwnProperty(i)) {
            elements[i].onclick = function () {
                this.parentElement.querySelector('.submenu').classList.toggle("active");
                this.classList.toggle("open");
            }
        }
    }
    return (
        <Fragment>
            <Helmet pageTitle="Trojdům - Kubelíkova" />

            {/* Start Header Area  */}
            <header className="header-area formobile-menu header--fixed default-color">
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <Link to={''}>
                                {/* <img src="assets/images/logo/LiebiegovyVyhledyLogoDarkBlue.png" alt="Liebiegovy Výhledy" ></img>*/}
                                <h5 className={`fw-light ${logoColorStyle} text-center my-auto`}>PROJEKT - KUBELÍKOVA</h5>
                            </Link>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li><LinkScroll activeClass="is-current" to="description" smooth={true} spy={true} onClick={CLoseMenuTrigger}>Popis</LinkScroll></li>
                                {/* <li><LinkScroll activeClass="is-current" to="news" smooth={true} offset={-100} spy={true} onClick={CLoseMenuTrigger}>Novinky</LinkScroll></li>*/}
                                <li><LinkScroll activeClass="is-current" to="about-project" smooth={true} offset={-100} spy={true} onClick={CLoseMenuTrigger}>O Projektu</LinkScroll></li>
                                <li><LinkScroll activeClass="is-current" to="about-souz" smooth={true} offset={-100} spy={true} onClick={CLoseMenuTrigger}>Společnost Souz</LinkScroll></li>
                                {/*<li><LinkScroll activeClass="is-current" to="buying-process" smooth={true} offset={-100} spy={true} onClick={CLoseMenuTrigger}>Proces Prodeje</LinkScroll></li>*/}
                                <li><LinkScroll activeClass="is-current" to="location" smooth={true} offset={-100} spy={true} onClick={CLoseMenuTrigger}>Lokalita</LinkScroll></li>
                                <li><LinkScroll activeClass="is-current" to="gallery" smooth={true} offset={-100} spy={true} onClick={CLoseMenuTrigger}>Galerie</LinkScroll></li>
                                <li><LinkScroll activeClass="is-current" to="video" smooth={true} spy={true} onClick={CLoseMenuTrigger}>3D Model</LinkScroll></li>
                            </ul>
                        </nav>
                        <div className="header-btn">
                            <Link className="rn-btn-contact" to={'kontakt'}>
                                <span>Kontakt</span>
                            </Link>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
            {/* End Header Area  */}

            {/* Start Slider Area   */}
            <div className="slider-activation slider-creative-agency" id="home">
                <div className="bg_image bg_image--27" data-black-overlay="4">
                    {SlideList.map((value, index) => (
                        <div className="slide slide-style-2  d-flex align-items-center justify-content-center" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title ">{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 white-btn" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start Description Area  */}
            <div className="service-area creative-service-wrapper pt--120 bg_color--1" id="description">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="feature-area">
                                <span>Dům UNO City - <a href="https://www.canaba.cz/rodinny-dum/uno-city" target="_blank" className="header-logo-blue">CANABA</a></span>
                                <h3 className="title fontWeight500 lineheight--1-8">Představujeme vám projekt aktuální výstavby trojdomu na ulici Kubelíkova. Jedná se o tři domy <a href="https://www.canaba.cz/rodinny-dum/uno-city" target="_blank" className="header-logo-blue">Canaba UNO CITY</a>, které jsou vedeny jako jednotky v jednom rodinném domě. Bytovou jednotkou je tedy pouze z právního pohledu, jelikož z konstrukčního i funkčního hlediska se jedná o plnohodnotné rodinné domy.   </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row service-one-wrapper mt--30">
                        {ServiceListOne.map((val, i) => (
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                <a >
                                    <div className="service service__style--1">
                                        <div className="icon big-margin-bottom">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h4 className="title">{val.title}</h4>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* End Description Area  */}


            {/* Start News Area  */}
            {/* 
            <div className="rn-featured-service-area pt--90 pb--120 bg_color--5" id="news">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 col-12 mt--30">
                            <div className="section-title">
                                <h2 className="title">Novinky</h2>
                                <p>Nenechte si ujít postup stavby rodinného domu Svítkov.</p>
                                <div className="service-btn">
                                    <Link className="btn-transparent rn-btn-dark"  to={'kontakt'}><span className="text">Dozvědět se Více</span></Link>
                                </div>
                            </div>
                        </div>
                            
                        <div className="col-lg-9">
                            <div className="row">
                                <NewsList />
                            
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        */}
            {/* End  News Area */}


            {/* Start About-Project Box  */}
            <div className="rn-content-box-area rn-content-box-style--1 pb--120 pt--60 bg_color--5 " id="about-project">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="section-title text-left service-style--3 mb--30">
                                <h2 className="title">O Projektu</h2>
                                <p>Podívejte se blíže na Váš vysněný domov.</p>
                            </div>
                        </div>
                        <div className="big-margin-bottom fw-light">
                            <h3 className="title">Aktuálně</h3>
                            <p className="small-text-custom">
                                V tuto chvíli je rezervována bytová jednotka C, tedy nejvíce vzdálená od silnice.
                                Nyní bychom vám rádi nabídli jednotku A a B a to každou za 8.640.000,-kč a to včetně právního servisu,
                                odměně realitnímu zprostředkovateli a 12% DPH.
                            </p>
                            <p className="small-text-custom">
                                Ve všech jednotkách byli nyní dokončeny topenářské práce a od příštího týdne nastupují zedníci.
                                Stavbu je nyní možné po dohodě projít a prohlédnout si dispozice osobně.
                            </p>
                            <p className="small-text-custom">
                                Dokončení projektu je plánováno na 5/2025 a je nejvyšší čas začít připravovat podklady. 
                                Pokud vás náš projekt zajímá a máte zájem o bližší informace neváhejte a ozvěte se nám.
                            </p>
                            <h3 className="title">O dostupných jednotkách</h3>
                            <p className="small-text-custom">
                                Dispozičně se jedná o chytře rozložený rodinný dům o dispozici 4+1, na 96- ti metrech čtverečních. 
                                Z právního hlediska je veden jako bytová jednotka, ze stavebního však se jedná o tři rodinné domy vedle sebe.
                                V přízemí nalezneme obývací pokoj, kuchyň, technickou místnost i menší koupelnu se sprchovým koutem a toaletou.
                                Pod schodištěm do prvního patra se pak nachází úložný prostor pro kočárek, sportovní vybavení, 
                                kola nebo může být využito i jako prostor pro vašeho domácího mazlíčka. Tento prostor překvapí svou velikostí.
                            </p>
                            <p className="small-text-custom">
                                V patře pak nalezneme větší místnost, která může sloužit jako ložnice a dva pokoje buď pro vaše potomky 
                                nebo poslouží jako pracovna a šatna. Dále zde najdeme koupelnu s vanou.
                            </p>
                            <p className="small-text-custom">
                                Ke každé bytové jednotce je vyprojektované minimálně jedno parkovací stání místo a jedno pro návštěvy. 
                                V rámci projektu bude zpevněná příjezdová plocha a upravená zahrada.
                                Ke každé jednotce se váže zastavěná plocha pod domem a minimálně 40m² vlastní zahrady která je situována na JIH / Východ 
                                kam také vedou dveře z obývacího pokoje. Je zde tedy možné si vybudovat terasu dle vlastních představ. 
                                Každá jednotka také bude mít vyvedený komín pro krbová kamna.
                            </p>
                            <h3 className="title">Záruka úspěchu</h3>
                            <p className="small-text-custom">
                                Kompletní dokončení projektu zaštiťuje Česká spořitelna kde máme k dispozici rámec k čerpání na dokončení dle projektu a to včetně rezervy.
                            </p>
                            <p className="small-text-custom">
                                Stavební firma Canaba je již v tuto chvíli z 95% proplacena a díky tomu již nyní bezpečně víme že každá jednotka bude dokončena na klíč
                                dle smluvních podkladů. Pokud by byl zájem je možné navštívit vilovou osadu společnosti Canaba kde vám budou ukázány materiály které budou použity.
                                Díky tomu že se jedná o výstavbu na klíč tak standardně není v ceně zahrnuta kuchyňská linka ale od společnosti Canaba máme pro vás připravenou
                                Slevu na kuchyňskou linku od společnosti Oresi.
                            </p>

                            <p className="small-text-custom">
                                Pokud by jste chtěli nákup jedné z jednotek financovat částečně prodejem vlastní nemovitosti tak vám  s tím velice rádi pomůžeme
                                ale v takovém případě je zapotřebí nás kontaktovat co nejdříve jelikož očekávané dokončení projektu je 5/2025 a
                                prodej vaší nemovitosti se vším všudy aby jste nebyli tlačeni zabere minimálně 3 měsíce.
                            </p>
                        
                            <p className="small-text-custom">
                                V případě že by jste potřebovali pomoci s hypotečním úvěrem spolupracujeme s profíky v oboru se kterými jsme ve spolupráci realizovali desítky obchodů.
                            </p>
                            <h3 className="title">Lokalita</h3>
                            <p className="small-text-custom">
                                Díky tomuto projektu máte jedinečnou možnost mít svůj nový domov na skvělé adrese, kde je kompletní občanská vybavenost. 
                                Za 5 minut dojedete na silnici 35, stejně tak do nákupního centra Nisa a průmyslové zóny. 
                                Zastávka MHD je v docházkové vzdálenosti jedné minuty.
                            </p>
                            <p className="small-text-custom">
                                Na všechny nepříjemné komplikace myslíme, pokud máte jakoukoliv obavu obraťte se na nás a ukážeme vám to.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row row--0 align-items-center big-margin-bottom">
                    <div className="col-lg-12 col-xl-6">
                        <div className="thumbnail">
                            <img src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/pudorys2.jpg" style={{ maxWidth: "85%" }} alt="Featured Images" />
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 mt_lg--50 mt_md--30 mt_sm--30">
                        <div className="content">
                            <p className="subtitle"><span>Přízemí</span></p>
                            <p>Dominantou přízemí je obývací pokoj a kuchyně. Najdete zde také samostatnou komoru a koupelnu s toaletou a sprchovým koutem.  </p>

                        </div>
                    </div>
                </div>

                <div className="row row--0 align-items-center big-margin-bottom">
                    <div id="ordered" className={`col-lg-12 col-xl-6 ${orderStyle}`}>
                        <div className="thumbnail" style={{ textAlign: "right" }}>
                            <img src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/pudorys1.jpg" style={{ maxWidth: "85%" }} alt="Featured Images" />
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 mt_lg--50 mt_md--30 mt_sm--30  ">
                        <div className="content">
                            <p className="subtitle mb--20"><span>1. Patro</span></p>

                            <p>V prvním patře najdete 3 ložnice a koupelnu s vanou a toaletou. Díky těmto dispozicím zde bude moci pohodlně bydlet 3-4 členná rodina. Jedná se o výstavbu "na klíč", ale je zde možné nechat si udělat drobné úpravy díky stavební firmě Canaba. Každá jednotka bude mít své parkovací místo. </p>

                        </div>
                    </div>

                </div>
                <div className="rn-counterup-area pt_sm--50 pt_md--50 pb--30 ">
                    <span className="text-center"><h4 className="fw-light">V okruhu 2 km naleznete:</h4></span>
                    <div className="container">
                        <CounterOne />
                    </div>
                </div>
            </div>
            {/* End About-Project Box  */}

            {/* Start About-Souz Box  */}
            <div className="rn-content-box-area rn-content-box-style--1 pb--60 pt--80 " id="about-souz">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="section-title text-left service-style--3 mb--30">
                                <h2 className="title">Společnost Souz</h2>
                                <p>Náš příběh.</p>
                            </div>
                        </div>
                        <div className="big-margin-bottom fw-light">

                            <p className="small-text-custom">Před třemi lety realitní makléř <a href={`https://www.tomas-zikmunda.cz`} target="_blank" style={{ fontWeight: "bold", color: "gray", textDecoration: "underline" }}>Tomáš Zikmunda</a> při prodeji bytu zastupoval svého známého pana Vladimíra Soukupa. Jelikož spolupráce byla na výborné úrovni a oba byli naladěni na stejnou vlnu, domluvili se na pokračování spolupráce a to již v přátelském duchu. Koncem roku 2020 byla společnost založena a od roku 2021 aktivně pracujeme na projektu na ulici Kubelíkova. </p>

                        </div>
                    </div>
                </div>

            </div>
            {/* End About-Souz Box  */}

            {/* Start Buying Process Box  *
            <div className="rn-content-box-area rn-content-box-style--1  pt--60 bg_color--5 " id="buying-process">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center service-style--3 mb--30">
                                <h2 className="title">Proces Prodeje</h2>
                            </div>
                        </div>
                        <div className="big-margin-bottom fw-light">
                            <p className="small-text-custom">

                                Celý projekt je financován bankovní společností Česká spořitelna a.s., díky čemuž je zde jistota dokončení projektu. Celý projekt je pojištěný. Se společnostní Česká spořitelna je domluveno čerpání na základě 100 % předprodanosti projektu.
                                Jak to vlastně funguje? Snadno, jakmile vás zaujme jednotka v našem projektu, <Link to={"kontakt"} className="header-logo-blue-small">kontaktujete nás</Link> a může začít celý proces. Jakmile budete mít veškeré informace a my budeme mít platné povolení stavby, budeme moci přikročit k administrativní části.
                                Začneme s podpisem rezervační smlouvy. Následovat bude smlouva o smlouvě budoucí kupní.Na základě těchto dokumentů budete potřebovat <span style={{ fontWeight: "bold",}}>800.000,-kč</span>. Částka <span style={{ fontWeight: "bold",}}>768.000,-kč</span> tedy <span style={{ fontWeight: "bold",}}>10%</span> kupní ceny putuje na projektový účet pod kontrolu banky. Jedná se o zálohu, na kterou my jako společnost nemůžeme sáhnout a slouží pro banku k čerpání prostředků na realizaci celého projektu. Pokud vám to připadá složité neváhejte se nás zeptat. Rádi vám vysvětlíme celý proces osobně a uvidíte, že není třeba se čehokoliv obávat.
                            </p>

                            <p className="small-text-custom">
                            Jakmile bude projekt dokončen a zkolaudován budete vyzváni k doplacení zbylé kupní části. Zbytek finančních prostředků bude zapotřebí po zhruba 7-11 měsících (na základě především vlivů počasí), kdy bude projekt dokončován. Díky tomuto máte jedinečnou možnost v klidu prodat svou nemovitost a získat tím potřebné prostředky a stejně tak si vyjednat hypoteční úvěr za těch nejlepších podmínek.
                            </p>
                            <p className="small-text-custom">
                            V případě že si to v průběhu výstavby rozmyslíte není důvod zoufat. Jen nás o této skutečnosti vyrozumíte a my vynaložíme veškeré úsilí k tomu abych za vás našli jiného zájemce. 
                            </p>
                            <p className="small-text-custom">
                            Jelikož také myslíme na to, jak se vám zde bude bydlet a chceme předejít možným neshodám, tak v průběhu výstavby bude založeno společenství vlastníků a nastaveny základní stanovy fungování jednotlivých vlastníků. Ty bude samozřejmě možné upravit dle dohody již konkrétních zájemců. Díky tomu také budete moci vystupovat jako jedna právnická osoba, což usnadní případné budoucí dokoupení pozemků nebo úpravy.
                                </p>

                                <p className="small-text-custom">
                                Nyní bychom vám rádi představili 3D Modelaci vypracovanou dle projektové dokumentace.
                                </p>
                        </div>
                    </div>
                </div>

            </div>
             End  Start Buying Process Box */}

            {/* Start Contact Map  */}

            <div className="rn-contact-map-area position-relative" id="location">

                <LocationMap />
            </div>
            {/* End Contact Map  */}

            {/* Start Portfolio Area  */}
            <div className="creative-portfolio-wrapper ptb--60  bg_color--5 " id="gallery">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center service-style--3 mb--30">
                            <h2 className="title ">Galerie</h2>
                            <p>Prohlédněte si fotografie nemovitosti.</p>
                            <a href={`https://www.canaba.cz/rodinny-dum/uno-city`} target="_blank" style={{ fontWeight: "bold", color: "gray", textDecoration: "none" }}>Jednotka UNO City <GoLinkExternal size={15} color="gray" /> </a>
                        </div>
                    </div>
                </div>
                <div className="container plr--10">
                    <Gallery />
                </div>
            </div>
            {/* End Portfolio Area  */}

            {/* Start Video Area  */}
            <div className="rn-section ptb--120 bg_color--1 line-separator" id="video">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center service-style--3 mb--30">
                            <h2 className="title ">3D Model</h2>
                          
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row sercice-details-content align-items-center">
                        <div className="col-lg-12">
                            <div className="thumb position-relative">
                                <img className="w-100" src="https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/kubelikova2NoBlack.jpg" alt="Svitkov thumbnail" />
                                <ModalVideo channel='vimeo' isOpen={videoOpen} autoplay={true} videoId='722540014?h=39ead2d545' onClose={() => setVideoOpen(false)} />
                                <button className="video-popup position-top-center" onClick={() => setVideoOpen(true)}><span className="play-icon"></span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Video Area  */}

            {/* Start Call To Action Area  */}
            <div className="call-to-action-wrapper call-to-action bg_image bg_image--30 text-white-wrapper ptb--180 ptb_lg--80 ptb_md--80 ptb_sm--80"   >
                <div className="container" >
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="inner text-left">
                                <span>PŘIPRRAVENI SPLNIT SI SEN?</span>
                                <h2>Pusťme se do toho</h2>
                                <Link className="rn-button-style--2 mt--70 mt_sm--30 mt_md--30" to={'kontakt'}><span>Kontaktujte Nás</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Call To Action Area  */}

            {/* Start Footer Style  */}
            <FooterTwo />
            {/* End Footer Style  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop />
            </div>
            {/* End Back To Top */}
        </Fragment>
    )
}

export default LandingPage;