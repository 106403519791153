import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import {Link as LinkScroll} from 'react-scroll';
import {Link } from 'react-router-dom';

const SocialShare = [
    //{Social: <FaFacebookF /> , link: 'https://www.facebook.com/P4Lreality/'},
   // {Social: <FaInstagram /> , link: 'https://www.instagram.com/jakub__hanus/'},
]

class Footer extends Component{
    render(){
        var getUrl = window.location;
        var baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[0];
        console.log(baseUrl);
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Zaujala Vás naše nabídka?</span>
                                        <h2>Tak na nic nečekejte a<br />  ozvěte se nám</h2>
                                        <LinkScroll  to={`contact`} 
                                        smooth={true} 
                                        className="rn-button-style--2 cursor-btn">
                                            <span>Kontaktní Formulář</span>
                                        </LinkScroll>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Odkazy</h4>
                                                <ul className="ft-link">
                                                    <li><Link to={''}>Domů</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Napište Nám</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:zikmunda.reality@gmail.com">zikmunda.reality@gmail.com</a></li>
                                                  
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`} target="_blank">{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2022 Souz s.r.o. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;