import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import {  FiPhone, FiMail , FiMapPin } from "react-icons/fi";
import LocationMap from "../component/common/LocationMap";
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from '../component/common/ScrollTop';
import Header from "../component/header/HeaderSecondary";
import Footer from "../component/footer/Footer";
import './Contact.css';
import LegalModal from "../component/common/LegalModal";
import Brand from "../elements/BrandTwo";
import Team from "../blocks/team/Team";

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Kontakt' />
                <LegalModal />
                <Header homeLink="/" logo="symbol-dark" color="color-black" />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--30"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title title-white ">Kontaktujte Nás</h2>
                                    <p>Neváhejte a sjednejte si prohlídku. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                       
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 contact-div">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Telefonní Číslo</h4>
                                        <p><a href="tel:+420 727 924 945">+420 727 924 945</a></p>
                                        <p><br /></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50 contact-div">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Emailová Adresa</h4>
                                        <p><a href="mailto:zikmunda.reality@gmail.com">zikmunda.reality@gmail.com</a></p>
                                        <p><br /></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50 contact-div">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Kancelář</h4>
                                        <p>Ještědská 110/67,  <br /> 460 08 Liberec</p>
                                     
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

     

              
               
                {/* Start Contact Map  */}
                  {/*  <div className="rn-contact-map-area position-relative">
                    <LocationMap />
                </div>*/}
                {/* End Contact Map  */}

                  {/* Start Contact Page Area  */}
                  <div className="rn-contact-page ptb--100 bg_color--1" id="contact">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop />
                </div>
                {/* End Back To Top */}
            
                <Footer />
                
            </React.Fragment>
        )
    }
}
export default Contact;